<template>
  <div class="clent-myTab1">
    <div v-if="isData.sign && isData.sign === '1'">
      <!-- <el-button>管理凭据</el-button> -->
      <el-input v-model="message" style="display: none"></el-input>
      <el-button
        v-if="isData.id"
        v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        >{{ $t("clientManagePage.copyID") }}</el-button
      >
      <el-input v-model="clipboard" style="display: none"></el-input>
      <el-button
        style="margin-left: 10px"
        v-if="isData.credential"
        v-clipboard:copy="clipboard"
        v-clipboard:success="onCopy"
        >{{ $t("clientManagePage.copyToken") }}</el-button
      >
    </div>
    <div class="client-main" v-if="isData">
      <els-form
        ref="elsForm"
        :column="column"
        v-model="cancelData"
        @change-name="changeName"
        @change-code="changeCode"
      ></els-form>
      <!-- <els-form
        ref="elsForm"
        :column="column"
        :model="cancelData"
        @submit="(d) => (form = d)"
      ></els-form> -->
    </div>
    <div class="footer">
      <el-button type="primary" v-if="edit" @click="editok">{{
        $t("d.edit")
      }}</el-button>
      <div v-if="!edit">
        <el-button @click="cancel">{{ $t("d.cancel") }}</el-button>
        <el-button type="primary" @click="changeEdit" :loading="isSure">{{
          $t("d.save")
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import elsForm from "../../../components/els-form/main.vue";
import { treeMap } from "@/utils/tree.js";
import { getIndustry, saveAsset } from "../../../api/base/client";
import { saveDevice } from "@/api/manager/client-manage.js";
import { timeset } from "../../../utils/client";
export default {
  name: "clent-myTab1",
  components: {
    elsForm,
  },
  props: {
    isData: Object,
    elstabs: Array,
  },
  computed: {
    column() {
      const c = this.elstabs;
      const d = this.isData;
      const taglib = d.taglib ? JSON.parse(d.taglib) : [];
      let col;
      const t = this.disablelist;
      col = treeMap(
        this.elstabs,
        (n) => {
          if (n.prop) {
            n.disabled = t.includes(n.prop);
          }
          if ((n.prop && n.prop === "selfTag") || n.prop === "selfSource") {
            n.value = taglib;
            n.column.forEach((o) => {
              if (o.prop) {
                o.form.disabled = this.dlist.includes(o.prop);
              }
            });
          }
          if (n.prop && n.prop === "firstLevelIndustry") {
            n.cls = this.firstIndustry;
          } else if (n.prop === "secondLevelIndustry") {
            n.cls = this.secondIndustry;
          }
        },
        { children: "cls" }
      );
      return col;
    },
    // copyData(v) {
    //   const d = this.isData;
    //   this.message = d.nId ? d.nId.id : ""; // 复制id;
    //   this.clipboard = d.credential ? d.credential : ""; //复制访问令牌
    //   return d;
    // },
  },
  created() {
    this.message = this.isData.nId ? this.isData.nId.id : ""; // 复制id;
    this.clipboard = this.isData.credential ? this.isData.credential : ""; //复制访问令牌
    this.getAsset();
    this.isData.gateway =
      !!this.isData.additionalInfo && this.isData.additionalInfo.gateway
        ? this.isData.additionalInfo.gateway
        : false;
    this.cancelData = this.isData
      ? JSON.parse(JSON.stringify(this.isData))
      : {};
  },
  watch: {
    cancelData(v) {
      if (v) return v;
    },
  },
  data: () => {
    return {
      isSure: false,
      saveRow: {},
      form: {},
      message: "",
      clipboard: "",
      edit: true,
      disablelist: [
        "code",
        "label",
        "name",
        "simNumber",
        "type",
        "phone",
        "firstLevelIndustry",
        "secondLevelIndustry",
        "additionalInfo",
        "selfSource",
        "type",
        "selfTag",
        "gateway",
      ],
      dlist: ["key", "value"],
      firstIndustry: [],
      secondIndustry: [],
      cancelData: {}, // 用来存储需要保存的值
    };
  },
  methods: {
    changeName(name) {
      // 改变资产名称/设备名称
      this.$emit("changeName", name);
    },
    changeCode(code) {
      this.$emit("changeCode", code);
    },
    onCopy(v) {
      // console.log(v.text);
      if (v.text) {
        this.$message({
          type: "success",
          message: "复制成功",
        });
      }
    },
    // 取消
    cancel() {
      this.$emit(
        "changeName",
        !!this.saveRow.name ? this.saveRow.name : this.isData.name
      );
      this.$emit(
        "changeCode",
        !!this.saveRow.name ? this.saveRow.code : this.isData.code
      );
      this.editok();
      this.cancelData = !!this.saveRow.name
        ? JSON.parse(JSON.stringify(this.saveRow))
        : JSON.parse(JSON.stringify(this.isData));
      this.$refs.elsForm.clearValidate();
      this.isSure = false;
    },
    async changeEdit() {
      this.isSure = true;
      const app = this.$refs.elsForm;
      const data = this.$refs.elsForm.formData;

      try {
        app && (await app.validate());
        const taglib = data.selfTag
          ? JSON.stringify(data.selfTag)
          : JSON.stringify(data.selfSource);
        if (!this.edit) {
          // 设备管理 保存

          if (this.isData.sign === "1") {
            const d = {
              lastOnlineTime:data.lastOnlineTime,
              status:data.status,
              id: data.nId,
              code: data.code,
              label: data.code,
              name: data.name,
              type: data.type,
              simNumber: data.simNumber,
              source: data.source,
              createdTime: timeset(),
              taglib: taglib,
              additionalInfo: { gateway: data.gateway },
            };
            saveDevice({ ...d })
              .then((res) => {
                if (res) {
                  this.isSure = false;
                  this.editok();
                  this.saveRow = res;
                  this.$message({
                    type: "success",
                    message: "保存成功",
                  });
                  this.$emit("setSussce", res);
                }
              })
              .catch((err) => {
                this.isSure = false;
                console.error(err);
              });
          } else {
            // 资产管理 保存
            const asset = {
              id: data.nId || data.id,
              code: data.code,
              name: data.name,
              type: data.type,
              simNumber: data.simNumber,
              additionalInfo: data.additionalInfo,
              sourceType: data.sourceType,
              firstLevelIndustry: this.serachIndustry(
                this.firstIndustry,
                data.firstLevelIndustry
              ),
              secondLevelIndustry: this.serachIndustry(
                this.secondIndustry,
                data.secondLevelIndustry
              ),
              // taglib: taglib,
            };
            saveAsset({ ...asset })
              .then((res) => {
                if (res) {
                  this.isSure = false;
                  this.$emit("setSussce", res);
                  this.editok();
                  this.saveRow = res;
                  this.$message({
                    type: "success",
                    message: "保存成功",
                  });
                }
              })
              .catch((err) => {
                this.isSure = false;
                console.log(err)
              });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    editok() {
      this.edit = !this.edit;
      this.disablelist =
        this.edit === true
          ? [
              "code",
              "simNumber",
              "label",
              "name",
              "type",
              "phone",
              "firstLevelIndustry",
              "secondLevelIndustry",
              "additionalInfo",
              "type",
              "selfTag",
              "selfSource",
              "gateway",
            ]
          : [];
      this.dlist = this.edit === true ? ["key", "value"] : [];
    },
    serachIndustry(data, v) {
      if (!isNaN(v)) {
        if (Array.isArray(data) && data.length > 0) {
          const item = data.filter((e) => {
            return e.label === v;
          });
          if (item.length !== 0) {
            return item[0].id.id;
          }
        }
      } else {
        return v;
      }
    },
    async getAsset() {
      const data = await getIndustry({ assetName: "" });
      data.forEach((item) => {
        if (!!item.fatherNode || item.industryLevel === "1") {
          this.secondIndustry.push({
            id: item.id,
            fatherNode: item.fatherNode,
            level: item.industryLevel,
            label: item.industryName,
            value: item.id.id,
          });
        } else {
          this.firstIndustry.push({
            id: item.id,
            fatherNode: item.fatherNode,
            level: item.industryLevel,
            label: item.industryName,
            value: item.id.id,
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep.el-tabs__content {
  display: none !important;
}
::v-deep .el-select {
  width: 100%;
}
.clent-myTab1 {
  .client-main {
    margin-top: 20px;
    width: 80%;
    .label {
      margin-bottom: 10px;
    }
  }
  .footer {
    margin-top: 10px;
    text-align: right;
  }
}
</style>
