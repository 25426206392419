<template>
  <div class="client-myTab5">
    <div class="client-header">
      <el-select v-model="value" size="mini" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.value"
          :value="item.label"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="value1"
        size="mini"
        style="margin-left: 10px"
        type="datetimerange"
        :range-separator="lang.to"
        :start-placeholder="lang.startDate"
        :end-placeholder="lang.endDate"
        format="yyyy-MM-dd hh:mm:ss"
        value-format="yyyy-MM-dd hh:mm:ss"
      >
      </el-date-picker>
      <!-- <i class="el-icon-search iconSearch"></i> -->
      <el-button
        size="mini"
        icon="el-icon-refresh"
        class="iconSearch"
        circle
        @click="reset"
      ></el-button>
    </div>
    <div class="client-table">
      <els-table
        ref="elsTable"
        style="height: 500px"
        :column="column"
        :onload="onload"
        :showMenu="false"
      ></els-table>
    </div>
  </div>
</template>
<script>
import elsTable from "../../../components/els-table/main.vue";
import { events } from "../../../api/base/client";
import { clientEvents } from "@/api/manager/client-manage.js";
import { timeChange } from "../../../utils/client";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "client-myTab5",
  mixins: [mixinsI18n],
  components: { elsTable },
  props: {
    isData: Object,
  },
  data: () => {
    return {
      PAGE_NAME: "clientManagePage",
      value: "",
      value1: [],
      start: "",
      end: "",
    };
  },
  computed: {
    options() {
      const data =[
      { value: this.lang.error, label: "ERROR" },
      { value: this.lang.lifeEvents, label: "LC_EVENT" },
      { value: this.lang.typeSta, label: "STATS" },
    ]
    return data
    },
    column() {
      const data = [
                { prop: "time", label: this.lang.eventTime, valueType: "date" },
                { prop: "server", label: this.lang.server },
                { prop: "method", label: this.lang.method },
                { prop: "wrong", label: this.lang.error },
              ]
      return data
    }
  },
  watch: {
    value(v) {
      if (v) this.$refs.elsTable.apiOnload();
    },
    value1(v) {
      this.start = !!v[0] ? timeChange(v[0]) : null;
      this.end = !!v[1] ? timeChange(v[1]) : null;
      this.$refs.elsTable.apiOnload();
    },
  },
  mounted() {
    this.value = this.options[0].label;
  },
  methods: {
    async onload() {
      try {
        const d = {
          entityType: this.isData.nId.entityType,
          entityId: this.isData.nId.id,
          type: this.value,
        };
        const b = {
          tenantId: this.isData.nId.id,
          limit: 999,
          startTime: this.start,
          endTime: this.end,
        };
        if (this.isData && this.isData.label === "1") {
          const data = await clientEvents(d, b);
          const total = data.length;
          return { data, total };
        } else {
          const data = await events(d, b);
          const total = data.length;
          return { data, total };
        }
      } catch (e) {
        console.log(e);
      }
    },
    reset() {
      this.value = this.options[0].label;
      this.value1 = [];
    },
  },
};
</script>
<style scoped lang="scss">
.client-myTab5 {
  .client-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .iconSearch {
      margin: 0px 10px;
    }
  }
}
</style>
