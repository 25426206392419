<template>
  <div class="client-drawer">
    <el-dialog
      :visible.sync="drawer"
      :with-header="false"
      @close="close"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-title">
        <span class="title-text">{{ code }}</span
        ><br />
        <span style="font-size: 12px">{{ name }}</span>
      </div>
      <div class="drawer-main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(col, ins) of tabs"
            :key="ins"
            :label="col.label"
            :name="col.name"
          ></el-tab-pane>
        </el-tabs>
        <component
          v-if="drawer"
          :is="currentComponent"
          :isData="datachange"
          :elstabs="taForms"
          @changeName="cName"
          @changeCode="cCode"
          @close="close"
          @setSussce="saveSuccess"
        ></component>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import elsDrawer from "../../../../components/els-drawer/main.vue";
import myTab1 from "../client-myTab1.vue";
import myTab2 from "../client-myTab2.vue";
import myTab3 from "../client-myTab3.vue";
import myTab4 from "../client-myTab4.vue";
import myTab5 from "../client-myTab5.vue";
import myTab6 from "../client-myTab6.vue";
import myTab7 from "../client-myTab7.vue";
// import { getDeviceType } from "@/api/manager/client-manage.js";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "client-drawer",
  mixins: [mixinsI18n],
  components: {
    elsDrawer,
    myTab1,
    myTab2,
    myTab3,
    myTab4,
    myTab5,
    myTab6,
    myTab7,
  },
  props: {
    data: Object,
    tabs: Array,
    disables: { type: Array, default: () => [] },
    deviceTypes:Array,
  },
  data: () => {
    return {
      name: "",
      code: "",
      drawer: false,
      componentArr: {},
      activeName: "1",
      currentComponent: "myTab1",
      // deviceType: [],
      PAGE_NAME: "clientManagePage",
    };
  },
  watch: {
    data(v) {
      this.name = v.name;
      this.code = v.code;
    },
  },
  created() {
    // this.getTypes();
  },
  mounted() {
    this.name = this.data.name;
  },
  computed: {
    datachange() {
      const d = this.data;
      if (d.sign === "1") {
        d.selfTag = d.taglib ? JSON.parse(d.taglib) : [];
      } else {
        d.selfSource = d.taglib ? JSON.parse(d.taglib) : [];
      }
      return d;
    },
    taForms() {
      let first = [];
      if (this.data.sign === "1") {
        first = [
          {
            tag: "div",
            title: this.lang.basicPro,
            cls: [
              {
                label: this.lang.terNum,
                tag: "el-input",
                prop: "code",
                required: true,
                // rules: [
                //   {
                //     pattern: /^\w{0,20}$/,
                //     message: "请输入20位以内的字母，数字，_组合",
                //     trigger: "blur",
                //   },
                // ],
              },
              {
                label: this.lang.terName,
                tag: "el-input",
                prop: "name",
                // rules: [
                //   {
                //     pattern: /^\w{0,20}$/,
                //     message: "请输入20位以内的字母，数字，_组合",
                //     trigger: "blur",
                //   },
                // ],
              },
              {
                tag: "el-select",
                label: this.lang.terType,
                placeholder: this.lang.pleaseSelect,
                required: true,
                prop: "type",
                cls: this.deviceType,
              },
              {
                label: this.lang.SIMNum,
                prop: "simNumber",
                tag: "el-input",
                // rules: [
                //   {
                //     pattern:
                //       /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
                //     message: "请输入正确的卡号",
                //     trigger: "blur",
                //   },
                //   { max: 11, message: "长度不能超过11个字符", trigger: "blur" },
                // ],
              },
              {
                label: "",
                tag: "el-checkbox",
                prop: "gateway",
                props: { label: this.lang.isGateway },
              },
              // {
              //   label: "自定义标签",
              //   prop: "selfTag",
              //   tag: "item-table",
              //   showPagination: false,
              //   column: [
              //     {
              //       prop: "key",
              //       label: "键",
              //       tag: "el-input",
              //       form: { tag: "el-input" },
              //     },
              //     {
              //       prop: "value",
              //       label: "值",
              //       form: { tag: "el-input" },
              //     },
              //   ],
              // },
            ],
          },
        ];
      } else {
        first = [
          {
            tag: "div",
            title: this.lang.basicPro,
            cls: [
              {
                label: this.lang.assetNum,
                tag: "el-input",
                prop: "code",
                required: true,
                // rules: [
                //   {
                //     pattern: /^\w{0,20}$/,
                //     message: "请输入20位以内的字母，数字，_组合",
                //     trigger: "blur",
                //   },
                // ],
              },
              {
                label: this.lang.assetName,
                tag: "el-input",
                prop: "name",
                // rules: [
                //   {
                //     pattern: /^\w{0,20}$/,
                //     message: "请输入20位以内的字母，数字，_组合",
                //     trigger: "blur",
                //   },
                // ],
              },
              {
                label: this.lang.assetType,
                tag: "el-input",
                required: true,
                prop: "type",
                rules: [
                  // {
                  //   pattern: /^\w{0,20}$/,
                  //   message: "请输入20位以内的字母，数字，_组合",
                  //   trigger: "blur",
                  // },
                  {
                    max: 20,
                    message: this.lang.max20,
                    trigger: "blur",
                  },
                ],
              },
              {
                tag: "el-select",
                label: this.lang.inCate,
                placeholder: this.lang.pleaseSelect,
                required: true,
                prop: "firstLevelIndustry",
              },
              {
                tag: "el-select",
                label: this.lang.inSecCate,
                placeholder: this.lang.pleaseSelect,
                required: true,
                prop: "secondLevelIndustry",
              },
              {
                label: this.lang.describe,
                type: "textarea",
                prop: "additionalInfo",
                tag: "el-input",
                rules: [
                  {
                    max: 50,
                    message: this.lang.max50,
                    trigger: "blur",
                  },
                ],
              },
            ],
          },
        ];
      }
      if (this.activeName === "1") {
        return first;
      }
      return "";
    },
    deviceType(){
      const types = this.deviceTypes || []
      return types.map(d => ({name:d.id.id,value:d.id.id,label:d.deviceTypeName}))
    }
  },
  methods: {
    saveSuccess(res) {
      const r = this.$parent.$refs.cTable || this.$parent.$refs.elstable;
      r.apiOnload();
    },
    cName(cName) {
      this.name = cName;
    },
    cCode(cCode) {
      this.code = cCode;
    },
    handleClick(num) {
      this.activeName = String(parseInt(num.index) + 1);
      const n = parseInt(num.index) + 1;
      this.currentComponent = `myTab${n}`;
    },
    // getTypes() {
    //   getDeviceType().then((res) => {
    //     const d = res;
    //     d.forEach((el) => {
    //       this.deviceType.push({
    //         name: el.id.id,
    //         value: el.id.id,
    //         label: el.deviceTypeName,
    //       });
    //     });
    //   });
    // },
    close() {
      this.activeName = "1";
      this.currentComponent = "myTab1";
      this.drawer = false;
      this.$emit("isClose");
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs__content {
  display: none;
}
</style>
