<template>
  <div class="client-myTab4">
    <div class="client-header">
      <el-select v-model="value" size="mini" :placeholder="lang.pleaseSelect">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.value"
          :value="item.label"
        >
        </el-option>
      </el-select>
      <el-date-picker
        size="mini"
        style="margin-left: 10px"
        v-model="value1"
        type="datetimerange"
        :range-separator="lang.to"
        :start-placeholder="lang.startDate"
        :end-placeholder="lang.endDate"
      >
      </el-date-picker>
      <el-button
        size="mini"
        icon="el-icon-refresh"
        class="iconSearch"
        circle
        @click="reset"
      ></el-button>
    </div>
    <div class="client-table">
      <els-table
        style="height: 500px"
        ref="elsTable"
        :column="column"
        :onload="onload"
        :showMenu="false"
      ></els-table>
    </div>
  </div>
</template>
<script>
import elsTable from "../../../components/els-table/main.vue";
import { alarm } from "../../../api/base/client";
import { clientAlarm } from "@/api/manager/client-manage.js";
import { timeChange } from "../../../utils/client";
import { timesort } from "../../../utils/client.js";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "client-myTab4",
  mixins: [mixinsI18n],
  components: { elsTable },
  props: {
    isData: Object,
  },
  data: () => {
    return {
      PAGE_NAME: "clientManagePage",
      value: "",
      value1: [], // 时间控件的时间选择
      start: "",
      end: "",
    };
  },
  computed: {
     options() {
       const data = [
      { value: this.lang.all, label: "ANY" },
      { value: this.lang.activated, label: "ACTIVE" },
      { value: this.lang.cleared, label: "CLEARED" },
      { value: this.lang.answered, label: "ACK" },
      { value: this.lang.notAnswered, label: "UNACK" },
    ]
     return data
     },
    column() {
       const data = [
                 { prop: "createdTime", label: this.lang.createTime, valueType: "date" },
                 { prop: "originatorName", label: this.lang.cause },
                 { prop: "type", label: this.lang.type },
                 { prop: "severity", label: this.lang.severity },
                 { prop: "status", label: this.lang.state },
                 { prop: "details", label: this.lang.details },
               ]
      return data
    }
  },
  watch: {
    value(v) {
      if (v) this.$refs.elsTable.apiOnload();
    },
    value1(v) {
      this.start = !!v[0] ? timeChange(v[0]) : null;
      this.end = !!v[1] ? timeChange(v[1]) : null;
      this.$refs.elsTable.apiOnload();
    },
  },
  mounted() {
    this.value = this.options[0].label;
  },
  methods: {
    async onload() {
      try {
        const d = {
          entityType: this.isData.nId.entityType,
          entityId: this.isData.nId.id,
        };
        const b = {
          limit: 999,
          startTime: this.start,
          endTime: this.end,
          searchStatus: this.value,
          fetchOriginator: true,
          ascOrder: false,
        };
        if (this.isData && this.isData.sign === "1") {
          const data = await clientAlarm(d, b);
          const total = data.data.length;
          const list = timesort(data.data, "createdTime");
          return { list: data.data, total };
        } else {
          const data = await alarm(d, b);
          const total = data.length;
          const list = timesort(data.data, "createdTime");
          return { list: data.data, total };
        }
      } catch (e) {
        console.log(e);
      }
    },
    reset() {
      this.value = this.options[0].label;
      this.value1 = [];
    },
  },
};
</script>
<style scoped lang="scss">
.client-myTab4 {
  .client-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .iconSearch {
      margin: 0px 10px;
    }
  }
}
</style>
